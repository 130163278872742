import React, { useContext, useEffect } from "react";
import letcContext from "../Context/letcContext";
import { Link } from "react-router-dom";
import examsList from "../Assests/exams-sheet.jpg";

const Exam = () => {
  const { getExams, exam } = useContext(letcContext);

  useEffect(() => {
    getExams();
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="md:pl-8 py-14 mt-20 md:mt-0 bg-blue-100 space-y-20">
      <div className="exam-notifications">
        <div className="mb-16">
          <h3 className="font-bold text-slate-700 text-2xl mb-5 ml-5 md:ml-0 tracking-wider">
            Exam Notification - Get all upcoming exam updates
          </h3>
        </div>
        <div className="w-full flex justify-center px-5">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5">
          <div
                      className="exam-notifications w-full space-y-3"
                      
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                       JEE MAIN EXAM-2024
                      </p>
                      <Link
                        to={'https://jeemain.nta.nic.in'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>

                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                        NEET UG EXAM_2024
                      </p>
                      <Link
                        to={'https://neet.nta.nic.in'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>



                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                      KEA Common Online Application-2024
                      </p>
                      <Link
                        to={'https://cetonline.karnataka.gov.in/UGONLINEAPPLICATION_2024/FORMS/Loginpage.aspx'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>

                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                      COMEDK UGET-2024
                      </p>
                      <Link
                        to={'https://www.comedk.org'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>



                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                        JEE ADVANCED-2024
                      </p>
                      <Link
                        to={'https://www.jeeadv.ac.in'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>


                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                       KCET COUNSELLING-2024
                      </p>
                      <Link
                        to={'https://cetonline.karnataka.gov.in/kea/'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>
                 

                 
          </div>
        </div>
      </div>
      <div className="exams-list">
        <div className="mb-16">
          <h3 className="font-bold text-slate-700 text-2xl mb-5 ml-5 md:ml-0 tracking-wider">
            Exams List - Some important exams
          </h3>
        </div>
        
            <div style={{width:'100%',padding:'30px'}} >
            <img
    src={examsList}
    alt="..."
    className="rounded-3xl"
    
  />
            </div>
         
      </div>
    </div>
  );
};

export default Exam;
