import React, { useEffect } from "react";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import courseTop from "../Assests/course-top.jpg";
import jee from '../Assests/jeebanner.jpg'
const Jee = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto py-14 mt-20 md:mt-0 px-6">
      <div className="course-img mb-12">
        <img
          src={jee}
          className="w-full h-[350px] rounded-3xl object-cover shadow-lg"
        />
      </div>
      <div className="about-us mb-16">
        <h1 className="font-bold text-indigo-700 text-4xl mb-8 tracking-wide text-center">
          IIT JEE 2025 Eligibility Criteria
        </h1>
        <p className="text-lg text-gray-600 text-center max-w-3xl mx-auto mb-12">
          Detailed eligibility criteria for JEE Mains and JEE Advanced exams in 2025, including age limits, minimum percentage requirements, and the number of attempts allowed.
        </p>
        <div className="bg-gray-50 p-8 rounded-2xl shadow-md text-gray-700">
          <h2 className="text-3xl font-bold text-indigo-600 mb-6">
            JEE Mains 2025
          </h2>
          <ul className="space-y-4 text-lg leading-relaxed">
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>
                General category candidates must secure at least <strong>75% marks</strong> in Std. XII board exams (with Mathematics and Physics as compulsory subjects along with Chemistry/Biology/Biotechnology) or any equivalent qualifying exam. (65% for SC/ST candidates) OR
              </span>
            </li>
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>Candidate must be in the top <strong>20 percentile</strong> of their respective Std. XII board.</span>
            </li>
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>There is <strong>no age limit</strong> to appear for JEE Main.</span>
            </li>
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>
                Candidate is eligible to appear for JEE Main in <strong>three consecutive years</strong> from the year they appear for Std. XII. (One attempt includes both January and April attempts of the same year.)
              </span>
            </li>
          </ul>
        </div>
        <div className="bg-gray-50 p-8 mt-10 rounded-2xl shadow-md text-gray-700">
          <h2 className="text-3xl font-bold text-indigo-600 mb-6">
            JEE Advanced 2025
          </h2>
          <ul className="space-y-4 text-lg leading-relaxed">
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
               <span>
                Candidates who crack JEE Main, satisfying all eligibility criteria, can appear for JEE Advanced.
               </span>
            </li>
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>Candidate should be in the top <strong>2,50,000 candidates</strong> (including reserved categories) clearing JEE Main – Paper 1.</span>
            </li>
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>General category candidates must secure at least <strong>75% marks</strong> in Std. XII board exams (with Mathematics and Physics as compulsory subjects along with Chemistry/Biology/Biotechnology) or any equivalent qualifying exam. (65% for SC/ST candidates) OR</span>
            </li>
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>Candidate must be in the top <strong>20 percentile</strong> of their respective Std. XII board.</span>
            </li>
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>Candidate is eligible to appear for JEE Advanced in <strong>two consecutive years</strong> from the year they appear for Std. XII.</span>
            </li>
            <li className="flex items-start">
              <TbArrowBadgeRightFilled className="mt-1 mr-3 text-indigo-500" />
              <span>Candidates who join any of the IITs, IT-BHU, Varanasi, or ISM, Dhanbad through JEE will not be permitted to appear for JEE in the future.</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center mt-12">
          <a
            href="https://jeemain.nta.ac.in"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
          >
            Visit Official JEE Website
          </a>
        </div>
    </div>
  );
};

export default Jee;
