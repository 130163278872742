import React, { useContext, useEffect } from "react";

import { Link } from "react-router-dom";

const ExamHome = () => {
  
  return (
    <div className="w-full mt-5 md:mt-0 mb-14 px-10">
      <div className="exam-notifications">
        <div className="mb-8">
          <h3 className="font-bold text-slate-700 text-2xl mb-5 tracking-wider">
            Latest Updates
          </h3>
        </div>
        <div style={{marginLeft:'80px'}} className="w-full flex justify-center">
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-3">
            
                    <div
                      className="exam-notifications w-full space-y-3"
                      
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                       JEE MAIN EXAM-2025
                      </p>
                      <Link
                        to='/jee'
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>

                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                        NEET UG EXAM_2025
                      </p>
                      <Link
                        to='/neet'
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>


                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                      KEA Online Application-2025
                      </p>
                      <Link
                        to={'https://cetonline.karnataka.gov.in/UGONLINEAPPLICATION_2024/FORMS/Loginpage.aspx'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>

                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                      COMEDK UGET-2025
                      </p>
                      <Link
                        to={'https://www.comedk.org'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>



                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                        JEE ADVANCED-2025
                      </p>
                      <Link
                        to={'https://www.jeeadv.ac.in'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>


                    <div
                      className="exam-notifications w-full space-y-3"
                      key={''}
                    >
                      <p className="font-bold text-base text-[#2E3192] tracking-wider break-words">
                       KCET COUNSELLING-2025
                      </p>
                      <Link
                        to={'https://cetonline.karnataka.gov.in/kea/'}
                        target="_blank"
                        className="font-medium blink text-xs text-red-500 tracking-wider"
                      >
                        Click here
                      </Link>
                    </div>
                 
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamHome;
