import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  
 

  return (
    <>
    <div className="w-full mt-5 md:mt-0 mb-14 px-10">
      <div className="w-full flex">
        <p className="px-2 py-1 bg-red-600 text-white tracking-wide font-light">
          Latest
        </p>
        <div id="scroll-container" className="bg-white w-full">
         
              
                <div
                  id="scroll-text"
                  className="font-bold text-base text-black tracking-widest whitespace-nowrap py-1"
                  
                >
                  <h1>

                   Welcome to Luminous Educational Training Centre Pvt. Ltd. Bangalore | We Provide The Best Quality Education for our students with the best Faculties.  

                 </h1>
                </div>
              
           
        </div>
      </div>
    </div>
    
    <div className="courses md:flex md:flex-row md:space-x-10 space-y-7 md:space-y-0 px-3 md:px-9 ">
        <div className="md:w-1/2 shadow-lg shadow-slate-300 border-2 border-slate-300 text-center py-10 rounded-lg bg-indigo-700  hover:scale-105 duration-300">
          <a href="/">
            <h4 className="font-bold text-4xl text-white mb-5 tracking-wider">
             LETC 
            </h4>
            <h4 className="font-bold text-3xl text-white tracking-widest mb-3">
              Gangamma Circle 
            </h4>
            <h4 className="font-bold text-xl text-white tracking-widest">
             
            </h4>
          </a>
        </div>
        <div className="md:w-1/2 shadow-lg shadow-slate-300 border-2 border-slate-300 text-center py-10 rounded-lg bg-indigo-700  hover:scale-105 duration-300">
          <Link to="/bagular">
            <h4 className="font-bold text-4xl text-white mb-5 tracking-wider">
              LETC 
            </h4>
            <h4 className="font-bold text-3xl text-white tracking-widest mb-3">
             Bagular
            </h4>
            <h4 className="font-bold text-xl text-white tracking-widest">
              
            </h4>
          </Link>
        </div>
      </div>
      <br/>
    </>
  );
};

export default Banner;
