import React from "react";
import { useContext } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import letcContext from "../Context/letcContext";
import { useEffect } from "react";

import image1 from "../Assests/1.jpeg";
import image2 from "../Assests/2.jpeg";
import image3 from "../Assests/3.jpeg";
import image4 from "../Assests/4.jpeg";
import image5 from "../Assests/5.jpeg";
import image6 from "../Assests/6.jpeg";
import image8 from "../Assests/8.jpeg";
import image9 from "../Assests/9.jpeg";
import image11 from "../Assests/11.jpeg";
import { Link } from "react-router-dom";

const GalleryHome = () => {
  const { getGallery, gallery } = useContext(letcContext);

  useEffect(() => {
    getGallery();
  });


  const staticImages = [
    image8,
    image3,
    image9,
    image4,
    image5,
    image6,
    
  ];

  const slideLeft = () => {
    var slider = document.getElementById("gallery-slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById("gallery-slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };
  return (
    <div className="container mx-auto pb-14">
      <div className="heading mb-10">
        <h3 className="text-slate-700 text-4xl font-bold text-center tracking-widest">
          Gallery
        </h3>
      </div>
      <div className="gallery">
        <div className="relative flex items-center">
          <MdChevronLeft
            className="opacity-50 cursor-pointer hover:opacity-100"
            onClick={slideLeft}
            size={40}
          />
          <div
            id="gallery-slider"
            className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
          >
          {staticImages.map((imageUrl, index) => (
            <a href="/gallery">
        <img
          className="w-[220px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300 rounded-3xl"
          
          src={imageUrl}
          alt={`Image ${index + 1}`}
          key={index}
        />
        </a>
          ))}
          </div>
          <MdChevronRight
            className="opacity-50 cursor-pointer hover:opacity-100"
            onClick={slideRight}
            size={40}
          />
        </div>
      </div>
    </div>
  );
};

export default GalleryHome;
