import React,{ useEffect }  from 'react';
import img1 from '../Assests/coaching.jpg';
import img2 from '../Assests/engineering.jpeg';
import img3 from '../Assests/neet.jpeg';
import img4 from '../Assests/commerce.png';
import banner from '../Assests/banner01.jpeg'

const Bagular = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  const courses = [
    {
      title: 'High School Level',
      img: img1,
    },
    {
      title: 'Engineering  Preparation',
      img: img2,
    },
    {
      title: 'Medical  Preparation',
      img: img3,
    },
    {
      title: 'Commerce',
      img: img4,
    },
  ];

  return (
    <>
    <div className="course-img mb-12 p-4">
  <img
    src={banner}
    alt="Course"
    className="w-full h-[350px] rounded-3xl object-cover shadow-lg"
  />
</div>

<div className="heading mb-10">
        <h3 className="text-slate-700 text-4xl font-bold text-center tracking-widest">
          Our Courses
        </h3>
      </div>
      <div className="flex justify-center">
      <div className="courses-container grid grid-cols-1 md:grid-cols-2 gap-8">
        {courses.map((course, index) => (
          <div
            key={index}
            className="course-container flex flex-col items-center transform transition-transform hover:scale-105 duration-300"
          >
            <div className="course-img mb-4 flex justify-center w-full">
              <img
                src={course.img}
                alt={course.title}
                className="w-4/5 h-[320px] rounded-3xl object-cover shadow-lg"
              />
            </div>
            <div className="course-info text-center mt-4">
              <h2 className="course-title text-2xl md:text-3xl font-bold text-gray-800 hover:text-blue-600 transition-colors duration-300 tracking-wider">
                {course.title}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </div>

    <br/>
    <br/>

    <div className="flex flex-col md:flex-row items-center bg-gray-100 p-4 md:p-6 rounded-3xl shadow-lg max-w-7xl mx-auto font-['Roboto']">
      <div className="contact-details md:w-5/12 text-left mb-8 md:mb-0 md:mr-6">
        <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-6 text-center md:text-left">
          Contact Us
        </h2>
        <p className="text-lg md:text-xl text-gray-700 mb-4">
          <strong className="text-xl font-semibold">Phone:</strong><br />
          +91 80234 53597,
          +91 93420 33334,<br />
          +91 93425 80693,
          +91 95909 13334
        </p>
        <p className="text-lg md:text-xl text-gray-700 mb-4">
          <strong className="text-xl font-semibold">Email:</strong><br />
          Letcbg4@gmail.com
        </p>
        <p className="text-lg md:text-xl text-gray-700">
          <strong className="text-xl font-semibold">Address:</strong><br />
          Dwarka Nagar, Bengaluru, Karnataka 560063
        </p>
      </div>
      <div className="course-iframe md:w-7/12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.6491473043106!2d77.61298957067226!3d13.121398983329671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19198c64d229%3A0x3d3d4a920909962d!2sL.E.T.C(Luminous%20Educational%20Training%20Centre)!5e0!3m2!1sen!2sin!4v1706085076464!5m2!1sen!2sin"
          title="Location"
          className="w-full h-[400px] rounded-3xl shadow-lg"
          allowFullScreen
        ></iframe>
      </div>
    </div>

    <br/>
    </>
  );
};

export default Bagular;
